.report-mobile_share {
  position: relative;
}

.report-mobile_share .report-mobile_lgbtn {
  z-index: 99;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.report-mobile_share .report-mobile_lgbtn .lgbtn {
  width: 100%;
}