:deep(.van-divider) {
  margin: 0.96rem 0;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mt-36 {
  margin-top: 0.96rem;
}

.text-right {
  text-align: right;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.wrapper * {
  box-sizing: border-box;
}

.wrapper .van-loading {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.7);
  z-index: 1;
}

.wrapper .personal-report {
  width: 100%;
  margin: 0 auto;
  overflow: auto;
}

.wrapper .personal-report header {
  background: url("~@/assets/images/personal-report-banner.png") top left;
  background-size: 100% 100%;
  padding: 0.64rem;
}

.wrapper .personal-report header h1.title {
  text-align: center;
  font-size: 0.533333rem;
  line-height: 0.533333rem;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0.506667rem;
}

.wrapper .personal-report header .divider {
  height: 0.026667rem;
  border: 0.026667rem solid #ffffff;
}

.wrapper .personal-report header .basic-info {
  justify-content: space-between;
  margin: 0.453333rem 0 0;
  font-size: 0.373333rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.533333rem;
}

.wrapper .personal-report header .basic-info-item>p {
  margin-bottom: 0.213333rem;
}

.wrapper .personal-report header .select-container {
  margin-bottom: 0.453333rem;
}

.wrapper .personal-report header .select-container :deep(.van-cell) {
  padding: 0.053333rem 0.266667rem;
  background: transparent;
  border-radius: 0.08rem;
  border: 0.026667rem solid #ffffff;
}

.wrapper .personal-report header .select-container :deep(.van-cell) .van-field__control {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.533333rem;
}

.wrapper .personal-report header .select-container :deep(.van-cell) .van-field__right-icon {
  color: #fff;
  line-height: 1;
}

.wrapper .personal-report header .select-container :deep(.van-cell) .van-field__right-icon .van-icon {
  font-size: 0.373333rem;
}

.wrapper .personal-report section {
  min-height: calc(100vh - 4.8rem);
  background-color: #fff;
  padding: 1.066667rem 0.64rem;
}

.wrapper .personal-report section .column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.wrapper .personal-report section .module-card {
  margin-bottom: 0.96rem;
}

.wrapper .personal-report section .module-card .module-title {
  font-size: 0.533333rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.746667rem;
  margin-bottom: 0.64rem;
}

.wrapper .personal-report section .module-card .module-title.left-icon {
  position: relative;
  padding-left: 0.266667rem;
}

.wrapper .personal-report section .module-card .module-title.left-icon::before {
  content: '';
  position: absolute;
  width: 0.106667rem;
  height: 0.48rem;
  background: #1890ff;
  border-radius: 0.053333rem;
  left: -0;
  top: 0.106667rem;
}

.wrapper .personal-report section .module-card .module-content h2.title {
  font-size: 0.426667rem;
  font-weight: 600;
  color: rgba(51,51,51,0.85);
  line-height: 0.586667rem;
  margin-bottom: 0.213333rem;
}

.wrapper .personal-report section .module-card .module-content h3.title {
  font-size: 0.373333rem;
  font-weight: 400;
  color: rgba(51,51,51,0.85);
  line-height: 0.533333rem;
  margin-bottom: 0.8rem;
}

.wrapper .personal-report section .module-card .module-content h4.title {
  font-size: 0.373333rem;
  font-weight: 600;
  color: rgba(51,51,51,0.85);
  line-height: 0.533333rem;
  margin-bottom: 0.213333rem;
}

.wrapper .personal-report section .module-card .module-content h4.title.mb-24 {
  margin-bottom: 0.64rem;
}

.wrapper .personal-report section .module-card .module-content .total-score {
  margin-bottom: 0.96rem;
}

.wrapper .personal-report section .module-card .module-content .total-score-area {
  margin-bottom: 0.64rem;
}

.wrapper .personal-report section .module-card .module-content .total-score-area .desc {
  margin-right: 0.4rem;
  font-size: 0.373333rem;
  font-weight: 400;
  color: rgba(98,112,152,0.65);
  line-height: 0.533333rem;
  margin-bottom: 0.426667rem;
}

.wrapper .personal-report section .module-card .module-content .total-score-area .card {
  background: #ffffff;
  box-shadow: 0.053333rem 0.053333rem 0.266667rem 0 rgba(169,193,205,0.34);
  border-radius: 0.08rem;
  padding: 0.533333rem;
  font-size: 0.373333rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.64rem;
  justify-content: space-between;
}

.wrapper .personal-report section .module-card .module-content .total-score-area .card.space {
  margin: 0.266667rem auto;
}

.wrapper .personal-report section .module-card .module-content .total-score-area .card .text {
  font-weight: 400;
}

.wrapper .personal-report section .module-card .module-content .total-score .copywriting {
  font-size: 0.373333rem;
  font-weight: 400;
  color: rgba(51,51,51,0.85);
  line-height: 0.533333rem;
  margin-bottom: 0.64rem;
}

.wrapper .personal-report section .module-card .module-content .total-score .rank-container {
  margin-bottom: 0.453333rem;
}

.wrapper .personal-report section .module-card .module-content .total-score .chart-container .chart-legend {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0.453333rem;
}

.wrapper .personal-report section .module-card .module-content .total-score .chart-container .chart-legend-item {
  justify-content: flex-start;
  width: 35%;
  margin-bottom: 0.32rem;
  font-size: 0.373333rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.64rem;
  white-space: nowrap;
}

.wrapper .personal-report section .module-card .module-content .total-score .chart-container .chart-legend-item .dot {
  display: inline-block;
  width: 0.32rem;
  height: 0.32rem;
  background: #1890ff;
  border-radius: 0.16rem;
  margin-right: 0.213333rem;
}

.wrapper .personal-report section .module-card .module-content .total-score .chart-container .chart {
  height: 6.666667rem;
  margin-bottom: 0.96rem;
}

.wrapper .personal-report section .module-card .module-content .total-score .score-list-item {
  font-size: 0.373333rem;
  font-weight: 400;
  line-height: 0.533333rem;
}

.wrapper .personal-report section .module-card .module-content .total-score .score-list-item .dimension-desc {
  color: rgba(51,51,51,0.85);
  margin-bottom: 0.64rem;
}

.wrapper .personal-report section .module-card .module-content .total-score .score-list-item .comment {
  color: #333333;
  margin-top: 0.453333rem;
}