.copyright-notice {
  margin: 0.64rem 0 0.533333rem 0;
  padding: 0.426667rem;
  background: #F5F5F5;
  border-radius: 0.213333rem;
  font-size: 0.373333rem;
}

.copyright-notice .note-title {
  font-weight: 500;
  font-size: 0.373333rem;
  color: #333333;
  line-height: 0.586667rem;
}

.copyright-notice .note-content {
  margin-top: 0.106667rem;
  font-weight: 400;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.533333rem;
}