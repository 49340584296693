.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/.video-player {
  width: 100%;
}

/deep/.video-player .video-js {
  width: 100%;
  height: 5.333333rem;
}

/deep/.video-player .video-js video {
  width: 100%;
}

/deep/.video-player .video-js .vjs-big-play-button {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.video-problem-personal .cards {
  padding-bottom: 0.426667rem;
  margin-bottom: 0.426667rem;
  border-radius: 0.426667rem;
  overflow: hidden;
  background: rgba(255,255,255,0.5);
}

.video-problem-personal .cards .cardtop {
  height: 1.546667rem;
  padding: 0.453333rem 0.426667rem;
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #EAEFFF 0%, rgba(255,255,255,0.5) 100%);
}

.video-problem-personal .cards .cardtop img {
  width: 0.64rem;
  margin-right: 0.213333rem;
}

.video-problem-personal .cards .cardtop .titl {
  color: rgba(0,12,33,0.65);
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 400;
}

.video-problem-personal .cards .cardtop .tet {
  color: #000C21;
  font-size: 0.48rem;
  font-style: normal;
  font-weight: 500;
}

.video-problem-personal .cards .carcen {
  margin: 0.426667rem;
  overflow: hidden;
  border-radius: 0.426667rem;
  border: 0.026667rem solid rgba(0,12,33,0.08);
}

.video-problem-personal .cards .carcen .carcentop {
  height: 1.28rem;
  display: flex;
  padding: 0 0.426667rem;
  align-items: center;
  background: #FFF;
  background: linear-gradient(90deg, rgba(248,227,198,0.5) 0%, rgba(242,189,116,0.7) 100%);
}

.video-problem-personal .cards .carcen .carcentop img {
  width: 0.426667rem;
}

.video-problem-personal .cards .carcen .carcentop .tite {
  color: #935C19;
  font-size: 0.426667rem;
  font-style: normal;
  font-weight: 500;
  margin: 0 0.106667rem;
}

.video-problem-personal .cards .carcen .suggest {
  color: #CD9955;
  text-align: justify;
  font-family: PingFang SC;
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.586667rem;
  padding: 0.32rem 0.426667rem;
  position: relative;
  overflow: hidden;
  border-radius: 0 0 0.426667rem 0.426667rem;
}

.video-problem-personal .cards .carcen .suggest .word {
  white-space: break-spaces;
}

.video-problem-personal .cards .carcen .suggest .mask {
  width: 100%;
  height: 100%;
  border-radius: 0 0 0.426667rem 0.426667rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(0.106667rem);
  -webkit-backdrop-filter: blur(0.106667rem);
}

.video-problem-personal .cards .carcen .suggest .mask .unlock {
  height: 0.8rem;
  padding: 0 0.32rem;
  border-radius: 1.013333rem;
  background: linear-gradient(90deg, rgba(248,227,198,0.5) 0%, rgba(242,189,116,0.7) 100%);
}

.video-problem-personal .cards .carcen .suggest .mask .unlock img {
  width: 0.373333rem;
}

.video-problem-personal .cards .carcen .suggest .mask .unlock span {
  color: #744E1F;
  text-align: justify;
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.586667rem;
  margin-left: 0.16rem;
}

.video-problem-personal .cards .issue {
  margin: 0 0.426667rem;
  margin-bottom: 0.8rem;
  border-radius: 0.426667rem;
  background: rgba(249,250,252,0.9);
  position: relative;
}

.video-problem-personal .cards .issue .lab {
  height: 0.746667rem;
  padding: 0 0.32rem;
  position: absolute;
  left: 0;
  top: -0.373333rem;
  text-align: center;
  line-height: 0.746667rem;
  background: linear-gradient(225deg, #033FFF 0%, #48A7FF 100%);
  border-radius: 0.426667rem 0.426667rem 0.426667rem 0.106667rem;
  color: #FFF;
  font-size: 0.426667rem;
  font-style: normal;
  font-weight: 500;
}

.video-problem-personal .cards .issue .iscot {
  color: rgba(0,12,33,0.85);
  text-align: justify;
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.586667rem;
  padding: 0.693333rem 0.426667rem 0.32rem;
}

.video-problem-personal .cards .answer {
  margin: 0 0.426667rem;
  border-radius: 0.426667rem;
  background: rgba(255,250,241,0.5);
  position: relative;
}

.video-problem-personal .cards .answer .lab {
  width: 2.346667rem;
  height: 0.746667rem;
  position: absolute;
  right: 0;
  top: -0.373333rem;
  text-align: center;
  line-height: 0.746667rem;
  background: linear-gradient(225deg, #FFA216 0%, #FFC700 100%);
  border-radius: 0.426667rem 0.426667rem 0.106667rem 0.426667rem;
  color: #FFF;
  font-size: 0.426667rem;
  font-style: normal;
  font-weight: 500;
}

.video-problem-personal .cards .answer .iscot {
  color: rgba(0,12,33,0.85);
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.586667rem;
  padding: 0.693333rem 0.426667rem 0.32rem;
}

.video-problem-personal .cards .answer .iscot .word {
  text-align: justify;
  margin-bottom: 0.426667rem;
}

.video-problem-personal .cards .answer .iscot .heightShrink {
  height: 1.76rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

.video-problem-personal .cards .answer .iscot .playback {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.video-problem-personal .cards .answer .iscot .playback .pbkbtn {
  width: 2.666667rem;
  height: 0.8rem;
  cursor: pointer;
  border-radius: 0.96rem;
  border: 0.026667rem solid #FFA714;
}

.video-problem-personal .cards .answer .iscot .playback .pbkbtn span {
  color: #FFA714;
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 400;
  margin-right: 0.106667rem;
  position: relative;
  top: 0.026667rem;
}

.video-problem-personal .cards .answer .iscot .playback .pbkbtn img {
  width: 0.426667rem;
}

.video-problem-personal .cards .answer .iscot .playback .expand {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.video-problem-personal .cards .answer .iscot .playback .expand span {
  color: #FFA714;
  font-family: PingFang SC;
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.586667rem;
  margin-right: 0.106667rem;
}

.video-problem-personal .cards .answer .iscot .playback .expand img {
  width: 0.426667rem;
  height: 0.426667rem;
}

.video-problem-personal .cards .answer .iscot .playback .expand .reversal {
  transform: rotate(180deg);
}

.video-problem-personal .cards .answer .iscot .koyou {
  justify-content: end;
}

.video-problem-personal .cards .answer .iscot .koyou .pbkbtn {
  margin-right: 0.426667rem;
}

.video-problem-personal .cards .cardbot .carditm {
  margin: 0 0.426667rem;
}

.video-problem-personal .cards .cardbot .carditm .citop {
  margin-bottom: 0.346667rem;
  display: flex;
  align-items: center;
}

.video-problem-personal .cards .cardbot .carditm .citop .category {
  color: #000C21;
  text-align: justify;
  font-size: 0.426667rem;
  font-style: normal;
  font-weight: 500;
}

.video-problem-personal .cards .cardbot .carditm .citop .scores {
  flex: 1;
}

.video-problem-personal .cards .cardbot .carditm .citop .grade {
  color: rgba(0,12,33,0.65);
  font-size: 0.373333rem;
  font-weight: 400;
}

.video-problem-personal .cards .cardbot .carditm .cibot {
  padding: 0.32rem 0.426667rem;
  border-radius: 0.426667rem 0.106667rem 0.426667rem 0.426667rem;
  background: #F4F7FF;
}

.video-problem-personal .cards .cardbot .carditm .cibot .word {
  color: rgba(0,12,33,0.85);
  text-align: justify;
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.586667rem;
}

.video-problem-personal .cards .cardbot .carditm .cibot .other {
  padding: 0.426667rem;
  border-radius: 0.213333rem;
  background: #FFF;
}

.video-problem-personal .cards .cardbot .carditm .cibot .other .ohitm {
  display: flex;
  align-items: center;
}

.video-problem-personal .cards .cardbot .carditm .cibot .other .ohitm img {
  width: 0.64rem;
  height: 0.64rem;
}

.video-problem-personal .cards .cardbot .carditm .cibot .other .ohitm .spw {
  color: rgba(0,12,33,0.85);
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.586667rem;
  margin: 0 0.426667rem 0 0.213333rem;
}

.video-problem-personal .cards .cardbot .carditm .cibot .other .ohitm .spl {
  flex: 1;
  margin-right: 0.426667rem;
}

.video-problem-personal .cards .cardbot .carditm .cibot .other .ohitm .spn {
  color: rgba(0,12,33,0.85);
  font-size: 0.373333rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.586667rem;
}

.video-problem-personal .mt-48 {
  margin-top: -1.28rem;
}

.video-problem-personal .pb-0 {
  padding-bottom: 0;
}

.video-problem-personal .video-player-banner {
  height: 100vh;
  display: flex;
  align-items: center;
}

.video-problem-personal .video-player-banner /deep/.video-js {
  height: 50vh !important;
}