h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.mt-36 {
  margin-top: 0.96rem;
}

h4.title {
  font-size: 0.373333rem;
  font-weight: 600;
  color: rgba(51,51,51,0.85);
  line-height: 0.533333rem;
  margin-bottom: 0.64rem;
}

.expression-horizontal .bar {
  display: flex;
  height: 0.48rem;
  border-radius: 0.026667rem;
  background: #f7f7f9;
  margin-bottom: 0.426667rem;
}

.expression-horizontal .legend {
  display: flex;
  flex-wrap: wrap;
}

.expression-horizontal .legend-item {
  display: flex;
  align-items: center;
  width: 33.3%;
  font-size: 0.373333rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.64rem;
  margin-bottom: 0.32rem;
}

.expression-horizontal .legend-item .dot {
  display: inline-block;
  width: 0.32rem;
  height: 0.32rem;
  border-radius: 50%;
  margin-right: 0.213333rem;
}

.expression-horizontal .legend-item .text {
  margin-right: 0.266667rem;
}

.expression-horizontal .rank-container .rank-item {
  margin-bottom: 0.64rem;
  font-size: 0.373333rem;
  line-height: 0.64rem;
  color: #333333;
}

.expression-horizontal .rank-container .rank-item .dot {
  display: inline-block;
  width: 0.32rem;
  height: 0.32rem;
  border-radius: 50%;
  margin-right: 0.213333rem;
}

.expression-horizontal .rank-container .rank-item .text {
  font-weight: 600;
  margin-right: 0.64rem;
}

.expression-horizontal .rank-container .rank-item .desc {
  font-weight: 400;
}