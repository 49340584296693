/deep/.video-player {
  width: 100%;
}

/deep/.video-player .video-js {
  width: 100%;
  height: 5.333333rem;
}

/deep/.video-player .video-js video {
  width: 100%;
}

/deep/.video-player .video-js .vjs-big-play-button {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.video-problem-personal .problem {
  margin-bottom: 0.666667rem;
}

.video-problem-personal .problem-answer-suggest {
  padding: 0.213333rem;
  background-color: #fffaf1;
  border-radius: 0.266667rem;
}

.video-problem-personal .problem-answer-suggest .suggest-title {
  font-weight: 600;
}

.video-problem-personal .problem-title {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.533333rem;
  margin-bottom: 0.32rem;
  white-space: normal;
  font-family: PingFangSC-Regular, PingFang SC;
}

.video-problem-personal .problem-origin-control {
  display: inline-block;
  font-size: 0.373333rem;
  font-weight: 400;
  color: #1890ff;
  line-height: 0.64rem;
  margin-bottom: 0.666667rem;
  cursor: pointer;
}

.video-problem-personal .problem-video {
  margin-bottom: 0.426667rem;
}

.video-problem-personal .problem-answer {
  margin-bottom: 0.666667rem;
}

.video-problem-personal .problem-capability {
  font-size: 0.373333rem;
  color: rgba(51,51,51,0.85);
  line-height: 0.533333rem;
  margin-bottom: 0.293333rem;
}

.video-problem-personal .problem-capability .title {
  font-weight: 600;
  margin-bottom: 0.213333rem;
}

.video-problem-personal .problem-capability .desc {
  font-weight: 400;
}

.video-problem-personal .problem-score-list {
  padding-bottom: 0.053333rem;
}

.video-problem-personal .problem-score-list .score-item {
  font-size: 0.373333rem;
  font-weight: 400;
  color: rgba(51,51,51,0.85);
  margin-bottom: 0.426667rem;
}

.video-problem-personal .problem-score-list .score-item .dot {
  width: 0.213333rem;
  height: 0.213333rem;
  background: #afb6ca;
  margin-right: 0.213333rem;
  border-radius: 0.106667rem;
}

.video-problem-personal .problem-score-list .score-item .title {
  display: flex;
  align-items: center;
  color: #333333;
  line-height: 0.533333rem;
  margin-bottom: 0.213333rem;
}

.video-problem-personal .problem-score-list .score-item .desc {
  color: #666666;
  line-height: 0.64rem;
}

.video-problem-personal .problem-idea {
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.453333rem;
  background: #f0f3f7;
  border-radius: 0.266667rem;
  padding: 0.213333rem 0.426667rem;
}

.video-problem-personal .problem-idea .title {
  font-weight: 600;
  margin-bottom: 0.053333rem;
}

.video-problem-personal .problem-idea .text {
  font-weight: 400;
}